exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const currentPosition = getSavedScrollPosition(location);
  const queriedPosition = getSavedScrollPosition({ pathname: location.pathname });

  // Scroll to the element if the location has a hash
  if (location.hash) {
    const el = document.getElementById(location.hash.substring(1));
    window.scrollTo({top: el.offsetTop, behavior: "smooth"});
  } else {
    // Restore the scroll position on back/forward navigation
    window.scrollTo(...(queriedPosition || [0, 0]));
  }

  return false;
};